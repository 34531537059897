import { Controller } from "@hotwired/stimulus"
import Chart from 'chart.js/auto';


// Connects to data-controller="graph-time"
export default class extends Controller {
  static targets = ['chart', 'sortFrom', 'sortTo']
  static values = { 
    data: Object,
    name: String
  }

  connect() {
    const chart = this.chartTarget
    const graphData = this.dataValue
    const nameValue = this.nameValue
    this.sortDates = {
      min: new Date(this.dataValue.dates[0]),
      max: new Date(this.dataValue.dates[this.dataValue.dates.length - 1])
    }
    this.period = 'day'
    

    const labels = graphData.dates
    const data = {
      labels: labels,
      datasets: [{
        label: nameValue,
        data: graphData.amount,
        fill: true,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }]
    };

    const config = {
      type: 'line',
      data: data,
      options: {
        responsive: true,
      },
    };

    this.chart = new Chart(chart, config)
  }

  months(){
    this._updateData(this._sortDataByPeriod(this.dataValue))
    this._monthForm()
    this.period = 'month'
  }

  years() {
    this._updateData(this._sortDataByPeriod(this.dataValue, 'year'))
    this._yearForm()
    this.period = 'year'
  }

  days() {
    this._updateData(this.dataValue)
    this._dayForm()
    this.period = 'day'
  }

  sort() {
    let start = String(this.sortFromTarget.value)
    let end = String(this.sortToTarget.value)
    this._updateData(this._sortDataByPeriod(this._sortedData(this.dataValue, start, end), this.period))
  }

  _sortedData(data, startDate, endDate) {
    let start;
    if (startDate.length === 4) {

      start = new Date(`${startDate}-01-01`);
    } else if (startDate.length === 7) {

      start = new Date(`${startDate}-01`);
    } else {
      start = new Date(startDate);
    }


    let end;
    if (endDate.length === 4) {

      end = new Date(`${endDate}-12-31`);
    } else if (endDate.length === 7) {

      const [year, month] = endDate.split('-').map(Number);
      end = new Date(year, month, 0);
    } else {
      end = new Date(endDate);
    }

    const filteredDates = [];
    const filteredAmounts = [];

    data.dates.forEach((date, index) => {
      const currentDate = new Date(date);

      if (currentDate >= start && currentDate <= end) {
        filteredDates.push(date);
        filteredAmounts.push(parseFloat(data.amount[index]));
      }
    });

    return {
      dates: filteredDates,
      amount: filteredAmounts,
    };
  }

  _sortDataByPeriod(data, period = "month") {
    const isYearSort = period.toLowerCase() === "year";
    const isMonthSort = period.toLowerCase() === "month";
    const isDaySort = period.toLowerCase() === "day";
    const groupedData = {};
    const countData = {}; 

    data.dates.forEach((date, index) => {
      let key;

      if (isYearSort) {
        key = date.split('-')[0]
      } else if (isMonthSort) {
        const [year, month] = date.split('-')
        key = `${year}-${month}`
      } else if (isDaySort) {
        key = date
      } else {
        throw new Error("Invalid period. Use 'day', 'month', or 'year'.")
      }

      const amount = parseFloat(data.amount[index]);
      if (!groupedData[key]) {
        groupedData[key] = 0;
        countData[key] = 0;
      }

      groupedData[key] += amount
      countData[key] += 1; 
    });
    const sortedKeys = Object.keys(groupedData).sort((a, b) => new Date(a) - new Date(b))

    return {
      dates: sortedKeys,
      amount: sortedKeys.map((key) => groupedData[key] / countData[key]),
    }
  }

  _updateData(newData){
    this.chart.data.datasets[0].data = newData.amount
    this.chart.data.labels = newData.dates
    this.chart.update()
  }

  _monthForm(){

    [this.sortFromTarget, this.sortToTarget].forEach((element, i) => {
      let monthVal = i > 0 ? this.sortDates.max.getMonth() + 1 : this.sortDates.min.getMonth() + 1

      element.value = ''
      element.type = 'month'
      element.value = `${this.sortDates.min.getFullYear()}-${String(monthVal).padStart(2, '0')}`
      element.min = `${this.sortDates.min.getFullYear()}-${String(this.sortDates.min.getMonth() + 1).padStart(2, '0')}`
      element.max = `${this.sortDates.max.getFullYear()}-${String(this.sortDates.max.getMonth() + 1).padStart(2, '0')}`
    });
  }

  _dayForm(){
    [this.sortFromTarget, this.sortToTarget].forEach((element, i) => {
      let monthVal = i > 0 ? this.sortDates.max.getMonth() + 1 : this.sortDates.min.getMonth() + 1
      let dayVal = i > 0 ? this.sortDates.max.getUTCDate() : this.sortDates.min.getUTCDate()

      element.value = ''
      element.type = 'date'
      element.value = `${this.sortDates.min.getFullYear()}-${String(monthVal).padStart(2, '0')}-${String(dayVal).padStart(2, '0')}`
      element.min = `${this.sortDates.min.getFullYear()}-${String(this.sortDates.min.getMonth() + 1).padStart(2, '0')}-${String(this.sortDates.min.getUTCDate()).padStart(2, '0')}`
      element.max = `${this.sortDates.max.getFullYear()}-${String(this.sortDates.max.getMonth() + 1).padStart(2, '0')}-${String(this.sortDates.max.getUTCDate()).padStart(2, '0')}`
    });
  }

  _yearForm(){
    [this.sortFromTarget, this.sortToTarget].forEach((element, i) => {

      element.value = ''
      element.type = 'number'
      element.step = 1
      element.value = i > 0 ? this.sortDates.max.getFullYear() : this.sortDates.max.getFullYear()
      element.min = this.sortDates.min.getFullYear()
      element.max = this.sortDates.max.getFullYear()
    });
  }
}
