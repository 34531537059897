import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="bt-messages"
export default class extends Controller {
  static targets = ['header', 'input', 'chatMessages']
  static values = {
    authorId: String,
    clientId: String,
    key: String
  }

  initialize() {
    this.state = []
    this.apiKey = this.keyValue
    $('#main_chat_frame').addClass('flex-1 relative')
  }

  connect() {
    $('#main_chat_frame').addClass('flex-1 relative')


    document.addEventListener('turbo:frame-load', (event) => {
      const frameElement = event.target; 
      if (frameElement.id === 'main_chat_frame') {
        let clientId = this.headerTarget.dataset.authorIdValue
        this._viewChat(clientId)
      }
    });

  }

  changeConvo(e) {
    this._getConvo(e.params.id, e.params.name)

    let convo
    if (e.target.id == `conversation-${e.params.id}`) {
      convo = e.target
    } else {
      convo = e.target.parentElement 
    }

    let icon = convo.children[convo.children.length - 1]
    if (icon.className == 'fa fa-bell') { 
      icon.remove() 
    }
  }

  sendMsg(e){
    let form = new FormData(e.target)
    form.append('intake_id', e.params.id)
    form.append('author_type', 'User')
    form.append('author_id', e.params.authorid)
    fetch(`/api/bt_messages?api_key=${this.apiKey}`,{
      method: 'POST',
      body: form
    })
      .then((resp) => resp.json())
      .then(json => {
        this._scrollToBottom()
        this.inputTarget.children[0].value = ''
      })
  }

  _viewChat(client_id) {
    let main_chat_id = $('#main_chat_frame')[0].src.split('=')[1]
    console.log(main_chat_id)
    console.log(this.authorIdValue)
    

    fetch(`/api/bt_messages/${main_chat_id}/read?api_key=${this.apiKey}`,{
      headers: {"Content-Type": "application/json"},
      method: 'PATCH',
      body: JSON.stringify({
        author_type: 'Client',
        author_id: client_id,
        read_at: new Date().toISOString()
      })
    })
      .then((resp) => resp.json())
      .then(json => console.log(json))
  }

  _getConvo(id, name){
    fetch(`/api/bt_messages/${id}?api_key=${this.apiKey}`)
      .then((resp) => resp.json())
      .then(json => {
        console.log(json)
        this.state = [...this.state, { header: name, msgs: json}]
        $('#main_chat_frame').attr('src', `bt_messages/main_chat_frame?intake_id=${json[0].intake_id}`)

        let client = json.find(m => m.author_type === 'Client')
        this._viewChat(client.author_id)
      })
  }

  _scrollToBottom(){
    let chatMessages = this.chatMessagesTarget
    chatMessages.scrollTop = chatMessages.scrollHeight;
  }

  _incoming(message){
    return (`
    <div class="flex mb-4 cursor-pointer">
      <div class="w-9 h-9 rounded-full flex items-center justify-center mr-2">
        <img src="https://placehold.co/200x/ffa8e4/ffffff.svg?text=ʕ•́ᴥ•̀ʔ&font=Lato" alt="User Avatar" class="w-8 h-8 rounded-full">
      </div>
      <div class="flex max-w-96 bg-white rounded-lg p-3 gap-3">
        <p class="text-gray-700">${message}</p>
      </div>
    </div>
    `)
  }

  _outgoing(message){
    return (`
    <div class="flex justify-end mb-4 cursor-pointer">
      <div class="flex max-w-96 bg-indigo-500 text-white rounded-lg p-3 gap-3">
        <p>${message}</p>
      </div>
      <div class="w-9 h-9 rounded-full flex items-center justify-center ml-2">
        <img src="https://placehold.co/200x/b7a8ff/ffffff.svg?text=ʕ•́ᴥ•̀ʔ&font=Lato" alt="My Avatar" class="w-8 h-8 rounded-full">
      </div>
    </div>
    `)
  }

}
