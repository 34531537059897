import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="affiliate-billing"
export default class extends Controller {
  connect() {
    this.id = $("#vendors").val()
    this.updateFrame()
  }

  changeAffiliate() {
    this.id = $("#vendors").val()
    if (this.id && this.id.length) {
      this.updateFrame()
    }
  }

  updateFrame() {
    $('#affiliate_billing_content').replaceWith(
      $('#loading_spinner').html()
    )

    fetch("/affiliate_billing_frame?" + new URLSearchParams({id: this.id}), {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}
